<template>
      <el-table
          :data="data"
          style="width: 100%"
          show-summary
          :summary-method="getSummaries"
      >
        <el-table-column type="expand">
          <template #default="props">
            <el-row :gutter="10">
              <el-col style="margin-bottom: 5px" :md="6" :lg="6" :xl="4" :xs="12" :sm="12" :key="key" v-for="(item, key) in props.row.data">
                <el-card :header="item.name">
                  <p>应收时间：{{ item.date }}</p>
                  <p>收款金额： {{ item.amount }} （ {{ item.remark }} ）</p>
                </el-card>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="月份" prop="title" />
        <el-table-column label="待收金额" prop="total" />
      </el-table>
</template>


<script>
  import {apiGetProjectNumbersPaddingPay} from "@/api/projectNumbersApi"
  import {getCompanyId} from "@/plugins/company"

  export default {
    created () {
      this.getPaddingPay()
    },
    data(){
      return {
        data: []
      }
    },
    methods: {
      getPaddingPay() {
        apiGetProjectNumbersPaddingPay({
          company_id: getCompanyId()
        }).then(res => {
          this.data = res
        })
      },
      getColorStyle (pay) {
        if (!pay || pay.need === 1) {
          return ''
        }

        if (pay.need === 2) {
          return 'color:green';
        } else {
          return 'color:red';
        }
      },
      getSummaries() {
        let total = 0
        this.data.forEach((item) => {
          total = parseFloat(item.total) + total
        })
        return {
          0: '',
          1: '合计',
          2: parseFloat(total).toFixed(2),
        }
      },
    }
  }
</script>
